@import './variables';

.pc {
  @media (max-width: $breakPoint) {
    display: none;
  }
}
.mobile {
  display: none;
  @media (max-width: $breakPoint)  {
    display: unset;
  }
}

.inner {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: $maxWidth;
  padding: 0 60px;
  @media (max-width: $breakPoint)  {
    padding: 0 40px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  width: 100vw;
  background-color: #FFFFFF;
  z-index: 9;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s linear;
  transform: translateY(0);
  @media (max-width: $breakPoint)  {
    box-shadow: none;
  }
  &.hide {
    transform: translateY(-80px);
  }
  .inner {
    justify-content: space-between;
    height: 80px;
    @media (max-width: $breakPoint)  {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      z-index: 2;
    }
  }

  .logo {
    font-size: 24px;
    line-height: 29px;
    font-weight: 900;
    color: black;
  }

  .nav_area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 64px;
    & nav {
      @media (max-width: $breakPoint)  {
        display: none;
      }
    }
    .user {
      display: flex;
      align-items: center;
      gap: 8px;
      .profile {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
  }

  .link_list {
    display: flex;
    align-items: center;
    gap: 48px;
    @media (max-width: $breakPoint)  {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }
  .link_item {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: black;
    & span {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      background: linear-gradient(90deg, #00CB82 0.92%, #1E1E1E 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .login {
    @include flex-row-center-center;
    width: 96px;
    height: 36px;
    border-radius: 32px;
    background-color: $primary;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: white;
    @media (max-width: $breakPoint)  {
      width: 42px;
      background-color: transparent;
      color: black;
      white-space: nowrap;
    }
  }

  // mobile
  .menu_container {
    display: none;
    justify-content: flex-start;
    align-items: center;
    width: 42px;
    height: 32px;
    .menu {
      display: flex;
      width: 32px;
      height: 32px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &::before {
        content: '';
        width: 32px;
        height: 32px;
        background: url("/images/common/menu.svg") 32px;
      }
      &.open::before {
        background: url("/images/common/close.svg") 32px;
      }
    }
    @media (max-width: $breakPoint)  {
      display: flex;
    }
  }
  .category {
    width: 100%;
    height: calc(100vh - 80px - env(safe-area-inset-bottom));
    padding: 0 40px;
    background-color: white;
    z-index: 1;
    & .link_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 80px;
      border-bottom: 1px solid #B9B9B9;
      &:nth-last-child(1) {
        border: none;
      }
    }
  }
  .join {
    @include flex-row-center-center;
    width: 100%;
    height: 36px;
    border-radius: 32px;
    background-color: $primary;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: white;
  }
}

.footer {
  @include flex-row-center-center;
  width: 100%;
  height: 320px;
  background-color: black;
  color: white;
  .inner {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    gap: 64px;
    & p {
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    gap: 16px;
    .left_bottom {
      display: flex;
      flex-direction: column;
      gap: 32px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      white-space: nowrap;
      .btn_area {
        display: flex;
        gap: 24px;
        & button {
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
          color: white;
        }
      }
    }
    & button[name=language] {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: white;
      //cursor: pointer;
      white-space: nowrap;
    }
  }
}

// modal
.background_modal, .background_sidebar {
  position: fixed;
  inset: 0;
  width: 100%;
  height: calc(100dvh - env(safe-area-inset-bottom));
  background-color: rgba(192, 192, 192, 0.5);
  backdrop-filter: blur(6px);
  z-index: 10;
}
.background_modal {
  @include flex-row-center-center;
  @include responsive-padding;
}

// alert modal (+welcome)
.modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: 480px;
  border-radius: 36px;
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

  @media (max-width: $breakPoint) {
    height: 400px;
  }

  &::before {
    content: '';
    width: 100%;
    height: 72px;
    border-radius: 36px 36px 0 0;
    background-color: #CCF0E3;
  }
  .content {
    @include flex-col-center-center;
    gap: 32px;
    width: 100%;
    height: calc(100% - 72px);
    @include responsive-padding;
    .title_area {
      @include flex-col-center-center;
      gap: 16px;
      .title {
        font-size: 1.5rem;
        line-height: 29px;
        font-weight: 700;
        color: black;
      }
    }
    .text_area {
      @include flex-col-center-center;
      font-size: 2rem;
      line-height: 38px;
      color: black;
      text-align: center;
    }
    .text {
      font-weight: 700;
    }
    .sub_text {
      font-weight: 400;
    }
    .guide {
      padding-top: 16px;
      font-size: 1.5rem;
      line-height: 29px;
      font-weight: 400;
    }
  }

}
.login_guide {
  padding: 8px 0;
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 400;
  color: black;
  text-align: center;
  & b {
    font-weight: 700;
  }
}

// 스튜디오 페이지 공유하기 버튼 클릭 시 링크 복사 모달
.share_modal {
  @include flex-col-center-center;
  gap: 24px;
  width: 100%;
  max-width: 720px;
  height: 240px;
  padding: 0 16px;
  border-radius: 32px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  background-color: white;

  @media (max-width: $breakPoint)  {
    justify-content: flex-start;
    height: 184px;
    padding-top: 36px;
  }

  .title {
    font-size: 1.5rem;
    line-height: 29px;
    font-weight: 700;
    color: black;
    @media (max-width: $breakPoint)  {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }
  .copy_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 480px;
    height: 40px;
    padding: 0 4px 0 24px;
    border-radius: 24px;
    background-color: #E6E6E6;
    @media (max-width: $breakPoint)  {
      justify-content: center;
      height: 24px;
      padding: 0;
    }
  }
  .link {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: black;

    @media (max-width: $breakPoint)  {
      font-size: 10px;
      line-height: 12px;
    }
  }
  .copy_btn {
    width: 72px;
    height: 32px;
    border-radius: 32px;
    background-color: $primary;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: white;
    white-space: nowrap;
    &:disabled {
      background-color: #A5A5A5;
    }
    @media (max-width: $breakPoint)  {
      position: absolute;
      bottom: -40px;
    }
  }

  .success_icon {
    position: absolute;
    right: -32px;
    opacity: 0;
    transition: opacity .5s ease-out;
    &.active {
      opacity: 1;
    }
    @media (max-width: $breakPoint)  {
      right: calc(100% / 2 - 66px);
      bottom: -36px;
    }
  }
}

.terms {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $maxWidth;
  margin: auto;
  @include responsive-padding;
  padding-top: 80px;
  & h2 {
    padding-bottom: 32px;
  }
  & p {
    line-height: 32px;
  }
  .table {
    max-width: 750px;
    border-collapse: collapse;
    text-align: left;
    font-size: 16px;
    th, td {
      padding: 10px;
      border: 1px solid #C1C7D0;
    }
  }
  & ul {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    & li {
      padding: 4px 0;
    }
  }

  .service_list {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: 32px 0 50px;
    padding: 0;
    border-bottom: 2px solid #DCDCDC;

    .service_item {
      position: relative;
      width: 150px;
      height: 41px;
      font-size: 16px;
      font-weight: 700;
      color: #DCDCDC;

      &.active {
        color: $text-black;
      }
      &.active::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: black;
      }
    }
  }
}


.contact {
  position: fixed;
  right: 60px;
  bottom: 40px;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background: url("/images/common/contact.svg") 60px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 11;
  @media (max-width: $breakPoint)  {
    right: 40px;
  }
}


// 범용 에러 모달
.error_modal {
  @include flex-col-center-center;
  gap: 40px;
  width: 100%;
  max-width: 600px;
  height: 480px;
  @include responsive-padding;
  border-radius: 36px;
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

  .title {
    @include flex-col-center-center;
    gap: 32px;
    font-size: 2rem;
    line-height: normal;
    font-weight: 700;
    color: black;
    text-align: center;
    white-space: nowrap;

    @media (max-width: $breakPoint)  {
      font-size: 24px;
    }

    &::before {
      content: '';
      width: 64px;
      height: 64px;
      background: url("/images/common/error.svg") 64px;
    }
  }
  .sub_area {
    @include flex-col-center-center;
    font-weight: 300;
    & span:nth-child(1) {
      font-size: 0.875rem;
      line-height: 17px;
      color: #A8A8A8;
      text-align: center;
    }
    & span:nth-child(2) {
      font-size: 1.25rem;
      line-height: 24px;
      color: $primary;
    }
  }
}

// loading
@keyframes loading-ani {
  0% {
    height: 100%;
  }
  50%, 100% {
    height: 24px;
  }
}
.loading_icon {
  @include flex-row-center-center;
  gap: 12px;
  position: absolute;
  width: 96px;
  height: 44px;
  z-index: 1;
  & div {
    flex: 1;
    border-radius: 32px;
    background-color: white;
    animation: loading-ani 1s ease-out infinite;
    &:nth-child(1) {
      animation-delay: -0.24s;
    }
    &:nth-child(2) {
      animation-delay: -0.12s;
    }
  }
}
