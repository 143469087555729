* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 16px;
}

html,
body {
  overflow-x: hidden;
}
body, button, input, textarea, select {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a {
  color: inherit;
  text-decoration: none;
}
li {
  list-style: none;
}
button {
  border: none;
  background-color: transparent;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  cursor: pointer;
}
button:disabled {
  cursor: default;
}

.mobile {
  display: none;
}

main {
  width: 100%;
  padding-top: 80px;
}

#container {
  width: 100%;
  max-width: 1080px;
  min-height: calc(100svh - 80px);
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
}

.divided-page {
  display: flex;
  padding-top: 0;
}
#dashboard {
  position: relative;
  flex: 1;
  height: 100svh;
  padding: 0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#dashboard::-webkit-scrollbar {
  display: none;
}

@media (max-width: 767px) {
  html {
    font-size: 14px;
  }
  #container {
    padding-left: 40px;
    padding-right: 40px;
  }
  #container.p-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  #dashboard {
    display: flex;
    flex-direction: column;
    min-width: unset;
  }
}

.toastui-editor-defaultUI {
  z-index: 0 !important;
}
.toastui-editor-contents {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 14px !important;
}
.toastui-editor-defaultUI-toolbar {
  padding: 0 12px !important;
}
.toastui-editor-main-container img {
  width: 300px !important;
}



/* swiper */
.swiper {
  width: 100% !important;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  width: 320px !important;
}
.swiper-slide:not(.swiper-slide-active) canvas {
  opacity: 0.3;
}

.swiper-button-prev, .swiper-button-next {
  top: 75% !important;
  width: 32px !important;
  height: 32px !important;
}
.swiper-button-prev {
  left: 30% !important;
}
.swiper-button-next {
  right: 30% !important;
}
.swiper-button-prev::after, .swiper-button-next::after {
  content: '' !important;
  width: 32px !important;
  height: 32px !important;
}
.swiper-button-prev::after {
  background: url("/images/lab/avatar/arrow-prev.svg") 32px;
}
.swiper-button-next::after {
  background: url("/images/lab/avatar/arrow-next.svg") 32px;
}

@media (max-width: 767px) {
  .mobile {
    display: inherit;
  }
  .swiper-slide {
    width: calc(100vw - 80px) !important;
  }

  .swiper-button-prev {
    left: 0 !important;
  }
  .swiper-button-next {
    right: 0 !important;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
