@import "/src/styles/variables";

$maxWidth: 1240px;

.header {
    position: fixed;
    top: $bannerHeight;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #F4F4F4;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(16px);
    z-index: 9;

    @media (max-width: $breakPoint) {
        border-bottom: none;
    }
}
.inner {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: $maxWidth;
    height: 100%;
    margin: auto;
    @include responsive-padding;

    @media (max-width: $breakPoint) {
        position: relative;
        border-bottom: 1px solid #EEEEEE;
    }
}

.logo_area {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    @media (max-width: $breakPoint) {
        gap: 12px;
    }

    .voli_list {
        display: flex;
        align-items: center;
        gap: 4px;

        .voli_item {
            font: {
                size: 12px;
                weight: 200;
            };
            color: #717171;

            &.active {
                font: {
                    weight: 400;
                };
                color: black;
            }
        }

        & hr {
            border: none;
            width: 1px;
            height: 11px;
            background-color: #DCDCDC;
        }
    }
}
.logo {
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;

    .actors {
        position: absolute;
        top: 0;
        right: -44px;

        @media (max-width: $breakPoint) {
            position: static;
            margin-bottom: 10px;
        }
    }

    & h1 {
        display: none;
    }

    @media (max-width: $breakPoint) {
        gap: 6px;
    }
}

.nav {
    padding-left: 7%;

    @media (max-width: $breakPoint) {
        position: relative;
        width: 100%;
        height: calc(100dvh - $headerHeight - $bannerHeight);
        padding: 0 40px;
        background-color: white;
    }
}
.link_list {
    display: flex;
    align-items: center;
    gap: 28px;
    padding-right: 8px;

    @media (max-width: $breakPoint) {
        width: 100%;
        padding-right: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }

    .link_item {
        position: relative;
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
        color: $text-black;
        overflow: visible;

        & button {
            font-size: 14px;
            font-weight: 300;
            color: $text-black;
        }

        @media (max-width: $breakPoint) {
            display: flex;
            align-items: center;
            width: 100%;
            height: 80px;
            border-bottom: 1px solid #EDEDED;
        }

        &:nth-last-child(1) {
            @media (max-width: $breakPoint) {
                border-bottom: none;
            }
        }

        &.active, &.active button {
            font-weight: 700;
        }
    }
}

.language {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    color: $text-black;
    white-space: nowrap;

    &::before, &::after {
        content: '';
        width: 20px;
        height: 20px;
    }
    &::before {
        margin-right: 8px;
        background: url("/images/common/header/language.svg") 20px;
    }
    &::after {
        background: url("/images/common/header/arrow_drop-down.svg") 20px;
    }

    @media (max-width: $breakPoint) {
        position: absolute;
        bottom: calc(40px + env(safe-area-inset-bottom));
    }
}
.start {
    padding: 12px 20px;
    border-radius: 16px;
    background-color: $primary;
    font-size: 14px;
    font-weight: 700;
    color: white;
    white-space: nowrap;

    &.studio {
        display: flex;
        gap: 8px;
        padding: 12px 12px 12px 20px;

        &::after {
            content: '';
            @include icon('/images/common/header/arrow-right.svg', 20px, 17px);
        }
    }
}


// mobile only
.menu_btn {
    width: 32px;
    height: 32px;
    margin-left: auto;

    &:focus {
        outline: none;
    }
    &::before {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        background: url("/images/common/header/menu.svg") 32px;
    }
    &.open {
        width: 20px;
        height: 20px;
        margin-right: 0;
        &::before {
            width: 20px;
            height: 20px;
            background: url("/images/common/header/close.svg") 20px;
        }
    }
}

.join {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: calc(84px + env(safe-area-inset-bottom));
    width: calc(100% - 80px);
    height: 48px;
    border-radius: 32px;
    background-color: $primary;
    font-size: 16px;
    font-weight: 700;
    color: white;
}
