@import './variables';

.inner {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: $maxWidth;
  padding: 0 60px;
  @media (max-width: $breakPoint)  {
    padding: 0 40px;
  }
}

.header, .footer {
  .logo {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
.header .logo {
  position: relative;
  gap: 4px;

  &.actors {
    @media (max-width: $breakPoint)  {
      width: 132px;
    }
  }

  & h1 {
    display: none;
  }
  & img:nth-child(1) {
    @media (max-width: $breakPoint)  {
      width: 44px;
      height: 22px;
    }
  }
  & img:nth-child(2) {
    @media (max-width: $breakPoint)  {
      width: 50px;
      height: 16px;
    }
  }
  & img:nth-child(3) {
    position: absolute;
    right: -44px;
    top: 2px;

    @media (max-width: $breakPoint)  {
      right: 2px;
      width: 24px;
      height: 6px;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  width: 100vw;
  z-index: 9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(32px);
  transition: top 0.1s ease 0s;

  @media (max-width: $breakPoint)  {
    box-shadow: none;
  }

  &.hide {
    display: none;
  }

  .inner {
    justify-content: space-between;
    height: 80px;
    @media (max-width: $breakPoint)  {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      z-index: 2;
    }
  }

  .nav_area {
    @media (max-width: $breakPoint)  {
      display: none;
    }
  }

  .button_area {
    display: flex;
    justify-content: flex-end;
    width: 112px;

    @media (max-width: $breakPoint)  {
      width: 63px;
    }
    .start_btn {
      padding: 8px 12px;
      border-radius: 8px;
      background-color: $primary;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      color: white;

      @media (max-width: $breakPoint)  {
        padding: 0;
        background-color: transparent;
        font-size: 18px;
        line-height: 21px;
        color: $textBlack;
        text-align: right;
      }
    }
    .user {
      display: flex;
      align-items: center;
      gap: 12px;

      .nickname {
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        color: $textBlack;
        white-space: nowrap;

        @media (max-width: $breakPoint)  {
          display: none;
        }
      }
    }
  }
}
.link_list {
  display: flex;
  align-items: center;
  gap: 28px;
  padding-right: 9px;
  @media (max-width: $breakPoint)  {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    padding: 0;
  }
}
.link_item {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: $textBlack;

  &.active {
    font-weight: 800;
  }
}

// mobile
.menu_container {
  display: none;
  justify-content: flex-start;
  align-items: center;
  width: 63px;
  height: 33px;
  .menu {
    display: flex;
    width: 32px;
    height: 32px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &::before {
      content: '';
      width: 32px;
      height: 32px;
      background: url("/images/common/menu.svg") 32px;
    }
    &.open::before {
      background: url("/images/common/close.svg") 32px;
    }
  }
  @media (max-width: $breakPoint)  {
    display: flex;
  }
}
.category {
  width: 100%;
  height: calc(100vh - 80px - env(safe-area-inset-bottom));
  padding: 0 40px;
  background-color: white;
  z-index: 1;
  .link_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #B9B9B9;

    &:nth-last-child(1) {
      border: none;
    }
  }
  .join {
    @include flex-row-center-center;
    width: 100%;
    height: 36px;
    border-radius: 32px;
    background-color: $primary;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: white;
  }
}

// footer
.footer {
  @include flex-row-center-center;
  width: 100%;
  background-color: $textBlack;
  color: white;
  .inner {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    gap: 64px;
    padding-top: 56px;
    padding-bottom: 56px;
    & p {
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .sns_btn_area {
      display: flex;
      gap: 16px;

      .youtube, .instagram {
        width: 32px;
        height: 32px;
      }
      .youtube {
        background: url("/images/common/footer/youtube.svg") 32px;
      }
      .instagram {
        background: url("/images/common/footer/instagram.svg") 32px;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    gap: 16px;

    @media (max-width: $breakPoint)  {
      flex-direction: column;
      align-items: flex-start;
    }
    .left_bottom {
      display: flex;
      flex-direction: column;
      gap: 32px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      white-space: nowrap;
      &:lang(en) {
        white-space: initial;
      }
      .btn_area {
        display: flex;
        gap: 24px;
        & a {
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
          color: white;
        }
      }
    }
    & button[name=language] {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      font-weight: 700;
      line-height: normal;
      color: white;
      cursor: pointer;
      white-space: nowrap;

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        background: url("/images/common/footer/language.svg") 20px;
      }
    }
  }
}

// modal
.background_modal, .background_sidebar {
  position: fixed;
  inset: 0;
  width: 100%;
  height: calc(100dvh - env(safe-area-inset-bottom));
  background-color: rgba(192, 192, 192, 0.5);
  backdrop-filter: blur(6px);
  z-index: 10;
}
.background_modal {
  @include flex-row-center-center;
  @include responsive-padding;
}

// alert modal (+welcome)
.modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: 480px;
  border-radius: 36px;
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

  @media (max-width: $breakPoint) {
    height: 400px;
  }

  &::before {
    content: '';
    width: 100%;
    height: 72px;
    border-radius: 36px 36px 0 0;
    background-color: $primary-light;
  }
  .content {
    @include flex-col-center-center;
    gap: 32px;
    width: 100%;
    height: calc(100% - 72px);
    @include responsive-padding;
    .title_area {
      @include flex-col-center-center;
      gap: 16px;
      .title {
        font-size: 1.5rem;
        line-height: 29px;
        font-weight: 700;
        color: black;
      }
    }
    .text_area {
      @include flex-col-center-center;
      font-size: 2rem;
      line-height: 38px;
      color: black;
      text-align: center;
    }
    .text {
      font-weight: 700;
      //white-space: nowrap;
    }
    .sub_text {
      font-weight: 400;
    }
    .guide {
      padding-top: 16px;
      font-size: 1.5rem;
      line-height: normal;
      font-weight: 300;
    }
  }

}
.login_guide {
  padding: 8px 0;
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 400;
  color: black;
  text-align: center;
  white-space: pre-wrap;
  & b {
    font-weight: 700;
  }
}

// 알파테스트 공지 팝업
.notice_modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 480px;
  padding: 73px 76px 42px 66px;
  border-radius: 36px;
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

  @media (max-width: $breakPoint)  {
    height: 430px;
    padding: 24px;
  }

  & button[name=close] {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
    background: url("/images/common/close-24.svg") 24px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 2.29px;
    bottom: 25.4px;
    width: 256px;
    height: 231px;
    background: url("/images/common/airplane.svg") 256px 231px;
    opacity: 0.5;
    z-index: 0;
    @media (max-width: $breakPoint)  {
      right: -35px;
    }
  }

  .title_area {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      display: flex;
      align-items: center;
      gap: 4px;
      & p {
        display: flex;
        flex-direction: column;
        width: fit-content;
        font-size: 1.5rem;
        line-height: 29px;
        font-weight: 300;
        background: linear-gradient(90deg, #00CB82 0.92%, #1E1E1E 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-decoration: underline;
        &::after {
          content: '';
          width: 100%;
          height: 2px;
          margin-top: -1px;
          background: linear-gradient(90deg, #00CB82 0.92%, #1E1E1E 100%);
        }
        @media (max-width: $breakPoint)  {
          font-size: 1.2rem;
        }
      }
      & svg {
        @media (max-width: $breakPoint)  {
          display: none;
        }
      }
    }

    & span:nth-child(2) {
      font-size: 2.5rem;
      line-height: 48px;
      font-weight: 600;
      color: black;
      @media (max-width: $breakPoint)  {
        font-size: 1.5rem;
      }
    }
    & span:nth-child(3) {
      font-size: 1rem;
      line-height: 19px;
      font-weight: 300;
      color: #646464;
    }
  }
  .status {
    display: flex;
    flex-direction: column;
    z-index: 1;

    & span:nth-child(1) {
      font-size: 0.875rem;
      line-height: 17px;
      font-weight: 300;
      background: linear-gradient(90deg, #00CB82 0.92%, #1E1E1E 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    & div {
      padding-bottom: 12px;
      & b {
        font-size: 4rem;
        line-height: 76px;
        font-weight: 800;
        background: linear-gradient(90deg, #00CB82 0.92%, #1E1E1E 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      & span {
        font-size: 0.875rem;
        line-height: 17px;
        font-weight: 300;
        color: #1B332A;
      }
    }
    & a {
      @include flex-row-center-center;
      width: 240px;
      height: 40px;
      border-radius: 8px;
      background-color: $primary;
      font-size: 0.875rem;
      line-height: 17px;
      font-weight: 700;
      color: white;
    }
  }

  .day_close {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    right: 0;
    bottom: -33px;
    font-size: 0.875rem;
    line-height: 17px;
    font-weight: 500;
    color: black;
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      background: url('/images/common/close-icon.svg') 16px;
    }
  }
}

// 스튜디오 페이지 공유하기 버튼 클릭 시 링크 복사 모달
.share_modal {
  @include flex-col-center-center;
  gap: 24px;
  width: 100%;
  max-width: 720px;
  height: 240px;
  padding: 0 16px;
  border-radius: 32px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  background-color: white;

  @media (max-width: $breakPoint)  {
    justify-content: flex-start;
    height: 184px;
    padding-top: 36px;
  }

  .title {
    font-size: 1.5rem;
    line-height: 29px;
    font-weight: 700;
    color: black;
    @media (max-width: $breakPoint)  {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }
  .copy_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 480px;
    height: 40px;
    padding: 0 4px 0 24px;
    border-radius: 24px;
    background-color: #E6E6E6;
    @media (max-width: $breakPoint)  {
      justify-content: center;
      height: 24px;
      padding: 0;
    }
  }
  .link {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: black;

    @media (max-width: $breakPoint)  {
      font-size: 10px;
      line-height: 12px;
    }
  }
  .copy_btn {
    width: 72px;
    height: 32px;
    border-radius: 32px;
    background-color: $primary;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: white;
    white-space: nowrap;
    &:disabled {
      background-color: #A5A5A5;
    }
    @media (max-width: $breakPoint)  {
      position: absolute;
      bottom: -40px;
    }
  }

  .success_icon {
    position: absolute;
    right: -32px;
    opacity: 0;
    transition: opacity .5s ease-out;
    &.active {
      opacity: 1;
    }
    @media (max-width: $breakPoint)  {
      right: calc(100% / 2 - 66px);
      bottom: -36px;
    }
  }
}

.terms {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $maxWidth;
  @include responsive-padding;
  & h2 {
    padding-bottom: 32px;
  }
  & p {
    line-height: 32px;
  }
}


.contact {
  position: fixed;
  right: 60px;
  bottom: 40px;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background: url("/images/common/contact.svg") 60px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 3;
  @media (max-width: $breakPoint)  {
    right: 40px;
  }
}


// 범용 에러 모달
.error_modal {
  @include flex-col-center-center;
  gap: 40px;
  width: 100%;
  max-width: 600px;
  height: 480px;
  @include responsive-padding;
  border-radius: 36px;
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

  .title {
    @include flex-col-center-center;
    gap: 32px;
    font-size: 2rem;
    line-height: 38px;
    font-weight: 700;
    color: black;
    text-align: center;
    white-space: nowrap;

    &::before {
      content: '';
      width: 64px;
      height: 64px;
      background: url("/images/common/error.svg") 64px;
    }
  }
  .sub_area {
    @include flex-col-center-center;
    font-weight: 300;
    & span:nth-child(1) {
      font-size: 0.875rem;
      line-height: 17px;
      color: #A8A8A8;
      text-align: center;
    }
    & span:nth-child(2) {
      font-size: 1.25rem;
      line-height: 24px;
      color: $primary;
    }
  }
}

// loading
@keyframes loading-ani {
  0% {
    height: 100%;
  }
  50%, 100% {
    height: 24px;
  }
}
.loading_area {
  @include flex-row-center-center;
  position: fixed;
  inset: 0;
  width: 100vw;
  height: calc(100dvh);
  z-index: 2;
}
.loading_icon {
  @include flex-row-center-center;
  gap: 12px;
  position: absolute;
  width: 96px;
  height: 44px;

  & div {
    flex: 1;
    border-radius: 32px;
    background-color: $primary-light;
    animation: loading-ani 1s ease-out infinite;
    &:nth-child(1) {
      animation-delay: -0.24s;
    }
    &:nth-child(2) {
      animation-delay: -0.12s;
    }
  }
}
