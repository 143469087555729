@import 'src/styles/variables';

.divider {
    width: calc(100% - 96px);
    height: 1px;
    margin: auto;
    border: none;
    background-color: #EDEDED;

    @media (max-width: $breakPoint) {
        width: calc(100% - 80px);
    }
}
@keyframes ani {
    from {
        left: -100%;
    }
    to {
        left: 0;
    }
}

.language {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: normal;
    color: $text-black;

    &::before, &::after {
        content: '';
        width: 20px;
        height: 20px;
    }
    &::before {
        margin-right: 8px;
        background: url("/images/common/header/language.svg") 20px;
    }
    &::after {
        background: url("/images/common/header/arrow_drop-down.svg") 20px;
    }
}

.background_menu {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100svh;
    background: rgba(0, 0, 0, 0.50);
    z-index: 10;
}
.menu-bar {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    min-width: 240px;
    max-width: 240px;
    height: calc(100dvh - $bannerHeight);
    background-color: #FAFAFA;

    @media (max-width: $breakPoint) {
        display: none;
        min-width: unset;
        max-width: unset;
    }

    .logo_area {
        display: flex;
        align-items: center;
        gap: 16px;
        height: 89px;
        padding: 0 16px;

        .main_link {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 24px;
            height: 24px;
            border-radius: 8px;
            &:hover {
                background-color: #D8D8D8;
                svg {
                    color: $text-black;
                }
                span {
                    display: block;
                }
            }

            span {
                display: none;
                position: absolute;
                top: 32px;
                left: 0;
                padding: 4px 8px;
                border-radius: 8px;
                background: $text-black;
                font-size: 14px;
                font-weight: 400;
                color: white;
                white-space: nowrap;
                z-index: 1;
            }
        }
    }

    &.open {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100vw - 103px);
        height: 100svh;
        z-index: 11;
        animation: ani .3s linear;
    }

    .language {
        margin-left: 40px;
        font-weight: 700;
    }
}

.beta {
    padding: 1px 2px 2px 2px;
    border: 0.5px solid #606060;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 300;
    color: $textBlack;
}

.close_btn {
    position: absolute;
    top: 32px;
    right: 20px;
    width: 20px;
    height: 20px;
    background: url("/images/common/header/close.svg") 20px;
}

.navigation {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    padding-bottom: 32px;
    border-bottom: 1px solid #ECECEC;
    overflow-y: scroll;
    @include scroll-style;

    &.scroll {
        box-shadow: rgba(0, 0, 0, 0.05) 0 12px 8px -8px inset, rgba(0, 0, 0, 0.05) 0px -12px 8px -8px inset;
        &.top {
            box-shadow: rgba(0, 0, 0, 0.05) 0px -12px 8px -8px inset;
        }
        &.bottom {
            box-shadow: rgba(0, 0, 0, 0.05) 0 12px 8px -8px inset;
        }
    }

    h2 {
        display: flex;
        align-items: center;
        gap: 4px;
        position: relative;
        padding: 8px 8px 8px 16px;
        font-size: 12px;
        font-weight: 700;
        color: #A2A2A2;
    }

    .question_btn {
        position: relative;
        width: 14px;
        height: 14px;
        &:hover {
            & + .labs_guide {
                display: block;
            }
        }
    }
    .labs_guide {
        display: none;
        position: absolute;
        bottom: -8px;
        padding: 4px 8px;
        border-radius: 8px;
        background-color: $text-black;
        font-size: 12px;
        font-weight: 400;
        color: white;
        white-space: pre-line;
        transform: translateY(-100%);
    }

    .menu_list {
        display: flex;
        flex-direction: column;

        .menu_item a {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 8px 24px;
            font-size: 14px;
            font-weight: 400;
            color: $text-black;

            &:hover {
                background-color: #ECECEC;
            }

            &.active {
                background-color: #D8D8D8;
                font-weight: 600;
            }

            .beta {
                margin-left: -4px;
            }
        }
    }
}

.type_list {
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: absolute;
    top: 82px;
    width: 175px;
    padding: 16px 12px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.10);
    z-index: 1;

    @media (max-width: $breakPoint) {
        top: unset;
        right: 0;
        bottom: 68px;
        border: 1px solid #E9E9E9;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
    }

    &.studio {
        top: 32px;
        left: 0;
        border: 1px solid #E9E9E9;
        z-index: 2;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);

        @media (max-width: $breakPoint) {
            top: 28px;
            bottom: unset;
            padding: 8px;
        }
    }

    .type_item {
        width: 100%;
        .type_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            position: relative;
            width: 100%;
            padding: 8px 12px 8px 12px;
            font-size: 14px;
            font-weight: 400;
            color: $textBlack;
            white-space: nowrap;
            cursor: pointer;

            &:hover {
                border-radius: 16px;
                background-color: #EDEDED;
            }

            &.record::before {
                content: '';
                @include icon('/images/studio2/record-icon.svg', 20px, 20px);
                min-width: 20px;
                min-height: 20px;
            }
            &.file::before {
                content: '';
                @include icon('/images/studio2/upload-icon.svg', 20px, 20px);
                min-width: 20px;
                min-height: 20px;
            }
            &.typing::before {
                content: '';
                @include icon('/images/studio2/keyboard-icon.svg', 20px, 20px);
                min-width: 20px;
                min-height: 20px;
            }

            & input[type=file] {
                display: none;
            }

            &.typing .beta {
                position: absolute;
                right: 8px;
            }
        }
    }
}

.profile_area {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 12px 0 32px;
    background-color: #FAFAFA;

    .upgrade_area {
        padding: 8px 32px 0 24px;
        .upgrade_btn {
            width: 100%;
            height: 30px;
            border: 0.5px solid #EEE;
            border-radius: 8px;
            background-color: #E9E9E9;
            font-size: 12px;
            font-weight: 700;
            color: $text-black;
            &:hover {
                background-color: $text-black;
                color: #FAFAFA;
            }
        }
    }

    .user_info {
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        padding: 8px 32px 8px 24px;

        .nickname {
            font-size: 14px;
            font-weight: 600;
            color: $text-black;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .plan {
            width: fit-content;
            padding: 1px 6px;
            border: 1px solid $primary;
            border-radius: 24px;
            font-size: 10px;
            font-weight: 400;
            color: $primary;
        }
        .settings {
            width: 20px;
            height: 20px;
            margin-left: auto;
        }
        .settings_list {
            position: absolute;
            top: 4px;
            right: 24px;
            border: 1px solid #E6E6E6;
            border-radius: 8px;
            background-color: white;
            transform: translateX(100%);
            z-index: 1;
            overflow: clip;

            @media (max-width: $breakPoint) {
                right: 76px;
                transform: translateX(0);
            }

            .settings_item button {
                padding: 12px 16px 8px 16px;
                font-size: 14px;
                font-weight: 400;
                color: $text-black;
                cursor: pointer;

                &:hover {
                    background-color: #E6E6E6;
                }
            }
        }
    }

    .usage_info {
        display: flex;
        flex-direction: column;
    }
    .title { // usage_info
        padding: 8px 8px 0 24px;
        font-size: 12px;
        font-weight: 500;
        color: #A2A2A2;
    }
    .usage {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 2px 32px 4px 24px;
    }
    .text { // usage_info
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 200;
        color: #717171;

        & b {
            font-size: 12px;
            font-weight: 600;
            color: $textBlack;
        }
    }
    .progress { // usage_info
        appearance: none;
        -webkit-appearance: none;
        @include flex-row-center-center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        &::-webkit-progress-bar {
            width: 20px;
            height: 20px;
            border: none;
            border-radius: 50%;
            background-color: #D9D9D9;
        }
        &::-webkit-progress-value {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
    }
}

///////////////////////////////////////////////////////////////

.title_area {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
    max-width: 1024px;
    padding: 32px 32px 8px 32px;

    @media (max-width: $breakPoint) {
        gap: 16px;
        padding: 20px;
    }

    .title {
        display: flex;
        align-items: center;
        gap: 4px;
        position: relative;
        width: fit-content;
        height: 24px;
        font-size: 20px;
        font-weight: 400;
        color: $text-black;

        @media (max-width: $breakPoint) {
            width: 100%;
            height: 32px;
            font-size: 16px;
        }
    }

    .text {
        min-height: 17px;
        font-size: 14px;
        font-weight: 300;
        color: #8D8D8D;
        white-space: pre-line;

        @media (max-width: $breakPoint) {
            font-size: 12px;
        }
    }

    .menu_btn {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin-left: auto;
        border: 1px solid #EEEEEE;
        border-radius: 4px;
        z-index: 1;
    }

    .btn_area {
        display: flex;
        gap: 16px;
        margin-top: -36px;

        button, a {
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 7px 12px;
            border-radius: 8px;
            background-color: #F0F0F0;
            font-size: 12px;
            font-weight: 600;
            color: $text-black;
        }
    }
}

.nav {
    width: 100%;
    border-bottom: 1px solid #EEEEEE;

    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1024px;
        padding: 16px 32px 0;

        @media (max-width: $breakPoint) {
            padding: 8px 20px 0;
        }
    }

    nav {
        display: flex;
        gap: 16px;

        a, button {
            display: flex;
            padding: 16px 0;
            font-size: 14px;
            font-weight: 400;
            color: #8D8D8D;

            @media (max-width: $breakPoint) {
                padding: 8px 0;
            }

            &.active {
                border-bottom: 2px solid $text-black;
                font-weight: 700;
                color: $text-black;
            }
        }
    }
}
