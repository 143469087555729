@import "/src/styles/variables";

@keyframes loading-ani {
    0% {
        height: 100%;
    }
    50%, 100% {
        height: 20px;
    }
}
.modal_background {
    @include flex-col-center-center;
    //gap: 32px;
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    padding: 0 20px;
    background: rgba(0, 0, 0, 0.80);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: white;
    z-index: 101;
}
.loading_icon {
    @include flex-row-center-center;
    gap: 6px;
    width: 48px;
    height: 32px;

    & div {
        flex: 1;
        border-radius: 32px;
        background-color: $primary-light;
        animation: loading-ani 1s ease-out infinite;
        &:nth-child(1) {
            animation-delay: -0.24s;
        }
        &:nth-child(2) {
            animation-delay: -0.12s;
        }
    }
}

// logo button component
.logo_btn_area {
    position: absolute;
    top: 87px;
    width: 100%;
    max-width: $maxWidth;
    z-index: 8;

    @media (max-width: $breakPoint) {
        top: 80px;
    }

    .logo_btn {
        @include flex-row-center-center;
        gap: 9px;
        position: absolute;
        top: 0;
        left: 60px;
        font-size: 24px;
        line-height: normal;
        font-weight: 900;
        color: $textBlack;

        @media (max-width: $breakPoint) {
            left: 40px;
        }

    }
}


// attribution banner component
.banner {
    @include flex-row-center-center;
    gap: 16px;
    position: sticky;
    top: 0;
    width: 100%;
    height: 40px;
    padding: 0 60px;
    background-color: #E0E0E0;
    cursor: pointer;
    z-index: 9;

    @media (max-width: $breakPoint) {
        padding: 0 20px;
    }

    .text {
        font-size: 12px;
        line-height: normal;
        font-weight: 400;
        color: black;
        word-break: keep-all;
    }
    & strong {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        line-height: normal;
        font-weight: 700;
        color: black;

        &::after {
            content: '';
            width: 12px;
            height: 12px;
            background: url("/images/studio/arrow.svg") 12px;
        }
    }
}

// header component
.header {
    position: sticky;
    top: 40px;
    width: 100%;
    height: 64px;
    border: 1px solid #EEEEEE;
    background-color: white;
    z-index: 9;

    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: $maxWidth;
        height: 100%;
        margin: 0 auto;
        padding: 0 60px;

        @media (max-width: $breakPoint) {
            padding: 0 20px;
        }

        .back_btn {
            position: absolute;
            left: 12px;
            @include icon('/images/dashboard/studio/arrow-back.svg', 16px, 16px);
        }
    }

    .type_area {
        position: relative;

        .type_btn {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            font-weight: 700;
            color: $textBlack;
            white-space: nowrap;
            cursor: pointer;

            &.default span {
                animation: twinkle 1.5s ease-in-out infinite alternate;
            }

            &.recording::before {
                content: '';
                @include icon('/images/studio2/record-icon.svg', 20px, 20px);
            }

            &.file::before {
                content: '';
                @include icon('/images/studio2/upload-icon.svg', 20px, 20px);
            }

            &.typing::before {
                content: '';
                @include icon('/images/studio2/keyboard-icon.svg', 20px, 20px);
            }

            &::after {
                content: '';
                margin-left: 3px;
                @include icon('/images/studio2/arrow-dropdown.svg', 12px, 12px);
                transition: all .3s linear;
            }

            &.open::after {
                transform: scaleY(-1);
            }
        }
    }

    .btn_area {
        display: flex;
        align-items: center;
        gap: 24px;

        @media (max-width: $breakPoint) {
            gap: 16px;
        }

        .review_btn {
            display: flex;
            flex-direction: column;
            gap: 2px;
            font-size: 10px;
            font-weight: 400;
            color: #622EF6;

            .stars {
                display: flex;
                gap: 4px;
            }

            &:disabled {
                color: #CCCCCC;

                & svg path {
                    stroke: #CCCCCC;
                }
            }

        }

        & button {
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 8px 16px;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 700;

            @media (max-width: $breakPoint) {
                padding: 8px;
            }

            &.share_btn:disabled, &.download_btn:disabled {
                border: 1px solid #CCCCCC;
                background-color: white;
                color: #CCCCCC;

                &::before {
                    background-color: #CCCCCC;
                }
            }

            &.share_btn {
                border: 1px solid $primary;
                color: $primary;
                &::before {
                    content: '';
                    @include icon-color('/images/studio2/share-icon.svg', 16px, 16px, $primary);
                }
            }
            &.download_btn {
                border: none;
                background-color: $primary;
                color: white;

                &::before {
                    content: '';
                    @include icon-color('/images/studio2/download-icon.svg', 16px, 16px, white);
                }
            }
        }
    }
}

// timeout error modal
.error_modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 468px;
    min-height: 264px;
    padding: 55px 52px 30px;
    border-radius: 36px;
    background-color: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

    @media (max-width: $breakPoint)  {
        justify-content: space-between;
        gap: unset;
        padding: 55px 16px 30px;
    }

    .text_area {
        @include flex-col-center-center;
        gap: 14px;
        position: relative;
        width: 100%;

        @media (max-width: $breakPoint)  {
            gap: 16px;
            padding-bottom: 24px;
        }

        .title {
            @include flex-col-center-center;
            gap: 16px;
            font-size: 20px;
            line-height: normal;
            font-weight: 700;
            color: black;
            text-align: center;
            white-space: pre-line;

            &:lang(en) {
                font-size: 16px;
            }

            &::before {
                content: '';
                width: 40px;
                height: 40px;
                background: url("/images/common/error-40.svg") 40px;
            }
        }

        .guide {
            width: 100%;
            font-size: 10px;
            font-weight: 300;
            color: $primary;
            text-align: center;
            white-space: pre-line;

            &:lang(en) {
                bottom: -36px;
                font-size: 12px;
            }

            @media (max-width: $breakPoint)  {
                position: unset;
                bottom: unset;
                font-size: 12px;
            }
        }
    }

    .btn_area {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2.5rem;
        width: 100%;

        .confirm_btn, .re_record_btn, .stop_btn {
            padding: 6.5px 26px;
            border-radius: 36px;
            font-size: 16px;
            font-weight: 700;
            color: white;

            @media (max-width: $breakPoint)  {
                padding: 10px 24px;
                font-size: 16px;
            }
        }

        .confirm_btn, .re_record_btn {
            background-color: $primary;
        }
        .stop_btn {
            background-color: #A5A5A5;
        }
    }
}


/* sound player */
.sound_player {
    display: flex;
    flex-direction: column;
    width: 100%;

    canvas {
        margin: 0 auto;
    }
}


.progress {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    margin: 48px 0 12px;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    transform-origin: left center;
    transition: all .3s linear;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: transparent;
    }
}

.control_area {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: $breakPoint) {
        margin-bottom: 16px;
    }

    .control_btn {
        @include icon-color('/images/studio2/play-btn.svg', 24px, 24px, $textBlack);

        &[name=pause] {
            @include icon-color('/images/studio2/pause-btn.svg', 24px, 24px, $textBlack);
        }
    }

    .time {
        font-size: 12px;
        font-weight: 300;
        color: #999999;
    }
}


// toggle
.toggle_area {
    @include flex-row-center-center;
    gap: 16px;

    .text_btn {
        font-size: 12px;
        font-weight: 700;
        color: #D9D9D9;
        &.active {
            color: $textBlack;
        }
    }
}
.toggle {
    & input[type=checkbox] {
        display: none;

        & + label {
            display: flex;
            align-items: center;
            position: relative;
            width: 45px;
            height: 18px;
            border-radius: 100px;
            background-color: #C7C7C7;
            cursor: pointer;

            @media (max-width: $breakPoint) {
                width: 31px;
                height: 12.4px;
            }

            &.active {
                background-color: $primary;
            }
        }
    }

    .toggle_btn {
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #EEEEEE;
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));
        transition: all .25s linear;

        @media (max-width: $breakPoint) {
            width: 16.53px;
            height: 16.53px;
            border: 1px solid #E5E5E5;
            filter: none;
        }
    }
}


// review component
.background {
    @include modal-background;
    @include responsive-padding;
    z-index: 99;

    @media (max-width: $breakPoint) {
        padding: 0 20px;
    }
}
.review {
    @include flex-col-center-center;
    gap: 16px;
    position: relative;
    width: 100%;
    max-width: 648px;
    height: 424px;
    padding: 0 84px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

    @media (max-width: $breakPoint) {
        padding: 0 40px;
    }

    .title {
        font-size: 1.5rem;
        line-height: 29px;
        font-weight: 700;
        color: #242424;
    }
    button[name=score] {
        width: 32px;
        height: 29px;
        background: url("/images/studio/star.svg") 32px 29px;
        &.active {
            background: url("/images/studio/star_active.svg") 32px 29px;
        }
    }
    button[name=submit] {
        width: 144px;
        height: 48px;
        border-radius: 36px;
        background-color: $primary;
        font-size: 1rem;
        line-height: 19px;
        font-weight: 700;
        color: white;
        &:disabled {
            background-color: #898989;
        }
    }

    .tip {
        position: absolute;
        bottom: 22px;
        font-size: 12px;
        font-weight: 500;
        color: $primary;
    }
}
.star_area {
    @include flex-row-center-center;
    gap: 8px;
    position: relative;
    width: 100%;
    padding-bottom: 22px;

    .text {
        position: absolute;
        bottom: 0;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: $primary;
    }
}
.textarea {
    @include flex-col-center-center;
    position: relative;
    width: 100%;
    textarea {
        width: 100%;
        height: 126px;
        margin: 8px 0;
        padding: 24px;
        border: 1px solid #B6B6B6;
        border-radius: 8px;
        font-size: 1rem;
        line-height: 19px;
        font-weight: 400;
        color: black;
        resize: none;
    }
    .placeholder {
        position: absolute;
        margin: 0 24px;
        font-size: 1rem;
        line-height: normal;
        font-weight: 400;
        color: #B6B6B6;
        text-align: center;
        tab-index: -1;
        white-space: pre-line;

        & b {
            font-weight: 600;
        }
    }
}
