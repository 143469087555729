@import "src/styles/variables";

.background {
    @include flex-col-center-center;
    position: fixed;
    inset: 0;
    padding: 0 32px;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(10px);
    z-index: 101;

    @media (max-width: $breakPoint) {
        padding: 0 20px;
    }
}

.component {
    @include flex-col-center-center;
    gap: 32px;
    height: 100%;

    @media (max-width: $breakPoint) {
        flex-direction: column-reverse;
        gap: 16px;
    }
}

.pagination {
    display: flex;
    gap: 16px;

    & button {
        width: 16px;
        height: 16px;
        border-radius: 32px;
        background: rgba(255, 255, 255, 0.30);

        &.active {
            width: 40px;
            background-color: white;
        }
    }
}

.content_area {
    @include flex-row-center-center;
    gap: 80px;
    max-width: 100%;

    & img {
        max-width: 100%;
        height: auto;
        cursor: pointer;
    }

    & button {
        width: 64px;
        height: 64px;

        @media (max-width: $breakPoint) {
            display: none;
        }

        &[name=prev] {
            background: url("/images/noticeModal/arrow_prev_active.svg") 64px;

            &:disabled {
                background: url("/images/noticeModal/arrow_prev.svg") 64px;
            }
        }

        &[name=next] {
            background: url("/images/noticeModal/arrow_next_active.svg") 64px;

            &:disabled {
                background: url("/images/noticeModal/arrow_next.svg") 64px;
            }
        }
    }
}

.btn_area {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 480px;
    margin-top: -8px;

    @media (max-width: $breakPoint) {
        padding-right: 20px;
    }

    .today_close {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        line-height: normal;
        font-weight: 500;
        color: white;

        &::before {
            content: '';
            width: 16px;
            height: 16px;
            background: url("/images/noticeModal/close-box.svg") 16px;
        }
    }
}

.background_confirm {
    @include flex-row-center-center;
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    padding: 60px;
    background: rgba(192, 192, 192, 0.50);
    backdrop-filter: blur(6px);
    z-index: 10;

    @media (max-width: $breakPoint) {
        padding: 20px;
    }
}
.confirm {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 560px;
    padding-top: 5.1875rem;
    padding-bottom: 2.5rem;
    border-radius: 36px;
    background-color: white;
    color: $text-black;

    .icon {
        margin-bottom: 1.5rem;
        font-size: 3rem;
        font-weight: 700;
    }
    .title {
        font-size: 1.5rem;
        font-weight: 700;
    }
    .text {
        margin-top: 0.5rem;
        font-size: 0.875rem;
        font-weight: 400;
    }

    .btn_area {
        justify-content: center;
        gap: 1.5rem;
        margin-top: 2.5rem;
        padding-right: 0;

        & button {
            width: 5rem;
            height: 2.5rem;
            border-radius: 40px;
            background-color: $primary;
            font-size: 0.875rem;
            font-weight: 700;
            color: white;

            &:nth-child(2) {
                background-color: #A5A5A5;
            }
        }
    }
}

.voice_slot_alert {
    @include flex-col-center-center;
    width: 100%;
    max-width: 560px;
    height: 337px;
    border-radius: 36px;
    background-color: white;
    color: $text-black;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);

    @media (max-width: $breakPoint) {
        height: 272px;
        border-radius: 28px;
    }

    img {
        @media (max-width: $breakPoint) {
            width: 42px;
            height: 42px;
        }
    }

    .title {
        margin-top: 1rem;
        padding-bottom: 0.5rem;
        font-size: 24px;
        font-weight: 700;

        @media (max-width: $breakPoint) {
            font-size: 19px;
        }
    }
    .text {
        padding-bottom: 2.5rem;
        font-size: 14px;
        font-weight: 400;

        @media (max-width: $breakPoint) {
            font-size: 11px;
        }
    }
    .link_btn {
        @include flex-row-center-center;
        width: 200px;
        height: 40px;
        border-radius: 40px;
        background-color: $primary;
        font-size: 14px;
        font-weight: 700;
        color: white;

        @media (max-width: $breakPoint) {
            width: 160px;
            height: 36px;
            font-size: 12px;
        }
    }
}

@keyframes appear {
    from {
        scale: 0.8;
        opacity: 0.5;
    }
    to {
        scale: 1;
        opacity: 1;
    }
}

@keyframes disappear {
    from {
        scale: 1;
        opacity: 1;
    }
    to {
        scale: 0.8;
        opacity: 0;
    }
}

.receive_updates {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 442px;
    height: 320px;
    padding: 43px 24px 30px;
    border-radius: 36px;
    background-color: white;
    color: $textBlack;
    white-space: pre-line;
    text-align: center;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
    scale: 0.8;
    opacity: 0.5;
    animation: appear .5s forwards;

    &.completed {
        padding-top: 82px;
        scale: 1;
        opacity: 1;

        .title {
            margin-top: 24px;
        }
    }

    .title{
        margin-top: 16px;
        font-size: 24px;
        font-weight: 700;
        color: $textBlack;
    }
    .text {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: $textBlack;
        text-align: center;
    }

    .btn_list {
        display: flex;
        gap: 8px;
        width: 100%;
        max-width: 274px;
        margin-top: 20px;

        .btn_item {
            height: 40px;
            border-radius: 36px;
            font-size: 14px;
            font-weight: 700;

            &:nth-child(1) {
                flex: 1;
                background-color: #EDEDED;
                color: #9C9C9C;
            }

            &:nth-child(2) {
                width: 160px;
                background-color: $primary;
                color: white;
            }
        }
    }

    .guide_text {
        margin-top: 12px;
        font-size: 10px;
        font-weight: 300;
        color: #888888;
    }

    .completed_text {
        margin-top: 16px;
        font-size: 12px;
        font-weight: 300;
        color: $textBlack;
    }
}

.preparation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 442px;
    height: 320px;
    padding: 43px 24px 30px;
    border-radius: 36px;
    background-color: white;
    color: $textBlack;
    white-space: pre-line;
    text-align: center;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
    scale: 0.8;
    opacity: 0.5;
    animation: appear .5s forwards;

    .title{
        margin-top: 16px;
        font-size: 24px;
        font-weight: 700;
        color: $textBlack;
    }
    .text {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: $textBlack;
        text-align: center;
    }
}

.permission_error {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 468px;
    height: 264px;
    padding: 56px 0 30px;
    border-radius: 36px;
    background-color: white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);

    .title {
        @include flex-col-center-center;
        gap: 16px;
        font-size: 20px;
        font-weight: 700;
        color: black;
        text-align: center;

        &::before {
            content: '';
            @include icon('/images/common/error.svg', 40px, 40px);
        }
    }

    .btn_area {
        justify-content: center;
        padding: 0;

        .confirm_btn {
            width: 80px;
            height: 32px;
            border-radius: 36px;
            background-color: $primary;
            font-size: 16px;
            font-weight: 700;
            color: white;
        }
    }
}


.credit_guide {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 480px;
    //aspect-ratio: 5 / 4;
    padding: 0 65px;
    border-radius: 36px;
    background-color: white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);

    @media (max-width: $breakPoint) {
        padding: 0 20px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 72px;
        //aspect-ratio: 100 / 12;
        border-radius: 36px 36px 0 0;
        background-color: #CDBDFC;
    }

    .title_cg {
        padding: calc(50px + 72px) 0 30px;
        font-size: 2rem;
        line-height: normal;
        font-weight: 700;
        text-align: center;
    }
    .guide_cg {
        padding: 44px 0 13px;
        font-size: 1rem;
        line-height: normal;
        font-weight: 400;
        white-space: nowrap;
    }
    .contents_cg {
        padding: 1rem 2.375rem;
        border-radius: 16px;
        background-color: #F2F2F2;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
    }
}

/* 보이스 저작권 및 사용 범위 안내 modal */
.background_guide {
    @include flex-row-center-center;
    position: fixed;
    inset: 0;
    padding: 0 60px;
    background: rgba(0, 0, 0, 0.40);
    z-index: 101;

    @media (max-width: $breakPoint) {
        padding: 0 12px;
    }
}
.voice_use_guide {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 530px;
    min-height: 713px;
    padding: 58px 30px 23px;
    border-radius: 24px;
    background-color: #FAFAFA;
    color: $text-black;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);

    @media (max-width: $breakPoint) {
        height: calc(100svh - 128px);
        padding: 48px 20px 32px;
        overflow-y: scroll;
        @include scroll-style;
    }

    .title {
        font-size: 20px;
        font-weight: 800;
        white-space: nowrap;
    }
    .text {
        font-size: 12px;
        font-weight: 400;
    }

    .voice_list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .voice_item {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            height: 156px;
            padding: 28px 24px 22px;
            border: 1px solid #EEE;
            border-radius: 16px;
            background-color: white;

            @media (max-width: $breakPoint) {
                height: auto;
            }

            .title_area {
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 16px;
                font-weight: 800;
            }

            .tag {
                padding: 4px 12px;
                border-radius: 16px;
                background-color: #EDEDED;
                font-size: 12px;
                font-weight: 500;
            }

            .tag_list {
                display: flex;
                gap: 8px;
                margin-bottom: 4px;

                .tag {
                    color: white;
                }
            }

            &:nth-child(1) .tag_list .tag {
                background-color: #00A505;
            }
            &:nth-child(2) .tag_list .tag {
                background-color: #FF6536;
            }
            &:nth-child(3) .tag_list .tag {
                background-color: $primary;
            }

            .text {
                font-size: 14px;
                font-weight: 300;
            }
        }
    }

    .confirm_btn {
        @include flex-row-center-center;
        gap: 8px;
        min-width: 173px;
        min-height: 48px;
        margin: 4px auto 0;
        border: 1px solid $text-black;
        border-radius: 64px;
        background-color: white;
        font-size: 16px;
        font-weight: 800;
        color: $text-black;

        &:hover {
            background-color: $text-black;
            color: white;

            svg {
                fill: white !important;
            }
        }
    }
    .hide_btn {
        padding-bottom: 10px;
        font-size: 12px;
        font-weight: 300;
        color: $text-black;
        text-decoration-line: underline;
        text-underline-position: under;
    }
}

/* Toast modal */
.toast {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 26px;
    right: 34px;
    width: 460px;
    padding: 24px;
    border: 1px solid #EBEBEB;
    border-radius: 16px;
    background-color: white;
    opacity: 0;
    z-index: 11;
    box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.25);
    transform: translateY(100%);
    transition: opacity 0.4s ease, transform 0.4s ease;

    @media (max-width: $breakPoint) {
        right: 20px;
        width: calc(100vw - 40px);
    }

    &.show {
        opacity: 1;
        transform: translateY(0);
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }

    .btn_area {
        display: flex;
        gap: 8px;
        width: 100%;
        margin-top: 14px;
        padding-right: 0;

        button {
            flex: 1;
            padding: 8px;
            border-radius: 8px;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1.5rem;
            &:nth-child(1) {
                border: 1px solid #9441EF;
                color: #9441EF;
            }
            &:nth-child(2) {
                background-color: $primary;
                color: white;
            }
        }
    }
}


/* 플랜 변경 동의 modal */
.background_agree {
    display: flex;
    justify-content: center;
    position: fixed;
    inset: 0;
    width: 100vw;
    min-height: 100vh;
    padding: 113px 60px 147px;
    background: rgba(192, 192, 192, 0.50);
    backdrop-filter: blur(6px);
    z-index: 10;
    overflow-y: scroll;

    @media (max-width: $breakPoint) {
        padding: 80px 20px;
    }
}
.agree {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    max-width: 538px;
    height: fit-content;
    padding: 60px 32px 32px;
    border-radius: 36px;
    background-color: white;
    color: $textBlack;
    white-space: pre-line;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);

    .title {
        padding-bottom: 8px;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;

        @media (max-width: $breakPoint) {
            font-size: 18px;
            line-height: normal;
        }
    }

    .benefits_area {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        max-width: 320px;
        margin-top: 8px;

        .label {
            font-size: 12px;
            font-weight: 200;
        }


        .benefit_list {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 20px 21px;
            border: 1px solid #E4E4E4;
            border-radius: 8px;

            .benefit_item {
                list-style: disc inside;
                font-size: 12px;
                font-weight: 400;
            }
        }

        &.new {
            .label {
                font-weight: 500;
            }

            .benefit_list {
                border: 1px solid transparent;
                background-image: linear-gradient(white, white), linear-gradient(90deg, #00B8FF, $primary, $primary);
                background-origin: border-box;
                background-clip: padding-box, border-box;

                .benefit_item:nth-child(1), .benefit_item:nth-child(2), .benefit_item:nth-child(5) {
                    color: $primary;
                }
            }

            .benefit_item {
                font-weight: 700;
            }
        }
    }

    .guide_area {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;
        max-width: 320px;
        font-size: 10px;
        font-weight: 400;
    }

    .button_area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        position: relative;
        width: 100%;
        max-width: 320px;
        margin-top: 19px;

        & span {
            display: flex;
            justify-content: center;
            position: absolute;
            top: -19px;
            padding: 6px 18px;
            border-radius: 36px;
            background-color: #F5C92D;
            font-size: 10px;
            font-weight: 700;

            &::after {
                content: '';
                position: absolute;
                bottom: -5px;
                width: 10px;
                height: 8px;
                background: url("/images/common/triangle.svg") 10px 8px;
            }
        }

        .upgrade_btn {
            width: 100%;
            min-height: 40px;
            border-radius: 36px;
            background-color: $primary;
            font-size: 12px;
            font-weight: 700;
            color: white;
        }

        .close_btn {
            font-size: 12px;
            font-weight: 700;
            color: $primary;
        }
    }
}
